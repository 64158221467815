import React, { useState, useEffect, useRef } from "react";

import { SvgClose, SvgSetaRight } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import './ShowGallery.css';

import { GetDataPage } from "interface/Data";

import { AddListDataSinglePoint, CleanDataPoint, GetDataPagePoint, RegisterDataPagePoint, UnRegisterDataPagePoint } from "interface/DataPoint";

import { Reg_Point } from "services/RegisterData";

export default function PopUP_ShowGallery(){

    const pointClick  = useRef();
    const pointImgAdd = useRef();

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    
    const [ idImg, setIdImg ]     = useState(0);
    const [ showImg, setShowImg ] = useState([]);
    const [ folder, setFolder ]   = useState('');

    const [ savePoint, setSavePoint ]     = useState([]);
    const [ statusPoint, setStatusPoint ] = useState(false);

    function InitialData(type, origin, id){
        const newData  = GetDataPage(origin).find(item => item.id == modalData.idPage);
        const listFile = newData.list_img.filter(item => item.type == modalData.type);

        if(type == 'list'){
            return listFile;
        }else {
            return listFile.indexOf(listFile.find(item => item.id == id));
        }        
    }

    function ClosePopUp(){
        CleanDataPoint("data_" + modalData.origin);
        setStatusPoint(false);
        SetModalState('ShowGallery', false);
    }

    function OpenAddPoint(value){
        setStatusPoint(value);
        if(value == false){
            CleanDataPoint("data_" + modalData.origin);
        }
    }

    function RegisterPoint(elem){
        setSavePoint([...elem]);
    }

    function SavePoint(){
        Reg_Point(modalData.origin, modalData.idPage, GetDataPagePoint("data_" + modalData.origin), ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterModalData('ShowGallery', setModaldata);
        RegisterModalObserver('ShowGallery', setShowPopUp);
        return ()=>{ };
    }, []);

    useEffect(()=>{
        if(showPopUp){
            RegisterDataPagePoint("data_" + modalData.origin, RegisterPoint);

            setIdImg(InitialData('index', modalData.origin, modalData.idImg));
            setFolder(modalData.folder);
            setShowImg(InitialData('list', modalData.origin, modalData.idImg));

            pointClick.current.addEventListener('click', (elem)=>{
                AddListDataSinglePoint("data_" + modalData.origin, { "id": 0, "imgPoint": pointImgAdd.current.currentSrc, "clientWidth": elem.target.clientWidth, "clientHeight": elem.target.clientHeight, "top": elem.offsetY, "left": elem.offsetX });
            });
            
        }else {
            if(modalData.origin != undefined){
                UnRegisterDataPagePoint("data_" + modalData.origin, RegisterPoint);
            }
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp AlignFile">
                    <div className="all show_gallery" style={ { backgroundColor: '#2d3c53' } }>
                        {
                            statusPoint == false ? 
                            <div className="div_arrow">
                                {
                                    idImg - 1 >= 0 ?
                                    <div className="arrow" onClick={ ()=>{ setIdImg(idImg - 1) } }>
                                        <SvgSetaRight className="icons icons_left" color="#ffffff" />
                                    </div> : 
                                    <div className="arrow">
                                        <SvgSetaRight className="icons icons_left" color="#535353" />
                                    </div>
                                }
                                <div className="space_white" />
                                {
                                    idImg < showImg.length - 1  ?
                                    <div className="arrow" onClick={ ()=>{ setIdImg(idImg + 1) } }>
                                        <SvgSetaRight className="icons" color="#ffffff" />
                                    </div> : 
                                    <div className="arrow">
                                        <SvgSetaRight className="icons" color="#535353" />
                                    </div>
                                }
                            </div>
                            : null
                        }

                        <div className="div_data type_div">
                            <div className="title"></div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="add_point">
                                    {
                                        modalData.origin == "disassemble" ? 
                                        <>                                        
                                            {
                                                statusPoint == false ? null : "Para adicionar um ponto basta clicar no local"
                                            }
                                            <div className="btn" onClick={ ()=>{ OpenAddPoint(!statusPoint); } }>
                                                {
                                                    statusPoint == false ? "Adicionar ponto" : "Cancelar ponto"
                                                }
                                            </div>
                                            {
                                                statusPoint == false ? null : 
                                                <div className="btn save_point" onClick={ ()=>{ SavePoint(); } }>
                                                    Salvar pontos
                                                </div>
                                            }
                                        </> : null
                                    }
                                </div>
                                <div className="div_img">
                                    <div className="div_square" ref={ pointClick } style={ { display: statusPoint == false ? "none" : "block" } }>
                                        {
                                            savePoint.map((elem, index)=>{
                                                return(
                                                    <div className="point_selected" key={ index } style={ { top: elem.top + "px", left: elem.left + "px" } }>
                                                        <div className="show_number_point">
                                                            { index + 1 }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        showImg.length > 0 ?
                                        <img ref={ pointImgAdd } alt="img" src={ showImg[idImg].img } className="show_img" /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        statusPoint == false ? <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } /> : null
                    }
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
