import { useState, useEffect } from "react";

import './Units.css';

import { SvgDelete, SvgEdit, SvgSearch, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { SetListPag } from "interface/Page";
import { Reg_AddFileCsv, Reg_ApartmentsUnits } from "services/RegisterData";

export default function Page_Units(props){

    const [ search, setSearch ]     = useState('');
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ showData, setShowData ] = useState(GetDataPage('apartments'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('apartments').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.status.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(GetDataPage('apartments'));
        }
        setSearch(value);
    }

    function HandleData(type, id, value){
        Reg_ApartmentsUnits(type, id, value, ()=>{ }, ()=>{ CallbackError() });
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('apartments', setShowData);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('apartments', setShowData);
        };
    }, []);

    // useEffect(()=>{
    //     setShowData(GetDataPage('apartments'));
    // }, [showData]);

    return(
        <div className="Page_Units">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#6a6a6a" className="icons" />
                    </div>
                    <div className="name_page">
                        Apartamentos
                    </div>
                </div>

                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th width="80">Andar</th>
                            <th width="80">Número</th>
                            <th width="80">Valor</th>
                            <th width="80">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>
                                            <input type="text" onChange={ (e)=>{ HandleData('title', elem.id, e.target.value) } } value={ elem.title } />
                                        </td>
                                        <td>
                                            <input type="text" className="number" onChange={ (e)=>{ HandleData('walk', elem.id, e.target.value) } } value={ elem.walk } maxLength="3" />
                                        </td>
                                        <td>
                                            <input type="text" className="number" onChange={ (e)=>{ HandleData('number', elem.id, e.target.value) } } value={ elem.number } maxLength="3" />
                                        </td>
                                        <td>
                                            <input type="text" className="cash" onChange={ (e)=>{ HandleData('cash', elem.id, e.target.value) } } value={ elem.cash } />
                                        </td>
                                        <td>
                                            <select onChange={ (e)=>{ HandleData('status', elem.id, e.target.value) } } value={ elem.status }>
                                                <option value="Disponível">Disponível</option>
                                                <option value="Vendido">Vendido</option>
                                                <option value="Reservado">Reservado</option>
                                            </select>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}