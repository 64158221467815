import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { SvgApartments, SvgDashboardService, SvgDisassemble, SvgGallery, SvgMap, SvgProject, SvgRotation, SvgScreen, SvgSetaRight, SvgSite, SvgSun, SvgTour, SvgVideo } from 'components/SvgFile';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'fixedData';

import { SvgClient, SvgDashboard, SvgDashboardExit, SvgLogoTipo, SvgLogoTipo_2, SvgMenuHamburguer, SvgMenuPoint, SvgUser, SvgUserDash } from 'components/SvgFile';

import PopUP_ShowFile from 'components/PopUp/ShowFile';
import PopUp_PhotoCrop from 'components/PopUp/PhotoCrop';
import PopUP_ShowFileTwo from 'components/PopUp/ShowFileTwo';
import PopUP_ShowGallery from 'components/PopUp/ShowGallery';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';

import Page_UsersDash from 'components/Page/UsersDash';
import Page_UsersDash_Details from 'components/Page/UsersDash/Details';

import Page_Solar from 'components/Page/Solar';
import Page_Solar_Details from 'components/Page/Solar/Details';

import Page_Disassemble from 'components/Page/Disassemble';
import Page_Disassemble_Details from 'components/Page/Disassemble/Details';

import Page_UsersSite from 'components/Page/UsersSite';
import Page_UsersSite_Details from 'components/Page/UsersSite/Details';

import Page_Project from 'components/Page/Project';

import Page_ScreenInitial from 'components/Page/ScreenInitial';

import Page_Gallery from 'components/Page/Gallery';
import Page_Gallery_Details from 'components/Page/Gallery/Details';

import Page_Video from 'components/Page/Video';
import Page_Video_Details from 'components/Page/Video/Details';

import Page_VirtualTour from 'components/Page/VirtualTour';

import Page_Map2 from 'components/Page/Map2';

import Page_MapPoi from 'components/Page/MapPoi';

import Page_Apartments from 'components/Page/Apartments';

import Page_Building from 'components/Page/Apartments/Building';

import Page_Apartments_Details from 'components/Page/Apartments/Details';

import Page_Config from 'components/Page/Config';

import Page_Floorplans_Details from 'components/Page/Floorplans/Details';

import Page_Units from 'components/Page/Units';

import Page_View360Rotation from 'components/Page/View360Rotation';
import Page_View360Floorplan from 'components/Page/View360Floorplan';
import Page_View360FloorplanPoints from 'components/Page/View360FloorplanPoints';

export default function Contents(){

    const cookies = new Cookies();

    const [ loading, setLoading ]         = useState(false);
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const [ status, setStatus ] = useState(false);

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
    }

    function CurrentPage(){
        window.history.pushState("", "", "/");        
        switch(currentPage) {
            case "config":
                return <Page_Config CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            case "access_dash":
                return <Page_UsersDash CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "access_dash_details":
                return <Page_UsersDash_Details CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            case "access_dash_site":
                return <Page_UsersSite CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "access_dash_site_details":
                return <Page_UsersSite_Details CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            case "solar":
                return <Page_Solar CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "solar_details":
                return <Page_Solar_Details CkickPage={ CkickPage } setLoading={ setLoading } />;

            case "view_360_rotation":
                return <Page_View360Rotation CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            case "view_360_floorplan":
                return <Page_View360Floorplan CkickPage={ CkickPage } setLoading={ setLoading } />;

            case "view_360_floorplan_points":
                return <Page_View360FloorplanPoints CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            case "disassemble":
                return <Page_Disassemble CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "disassemble_details":
                return <Page_Disassemble_Details CkickPage={ CkickPage } setLoading={ setLoading } />;                

            case "project":
                return <Page_Project CkickPage={ CkickPage } setLoading={ setLoading } />;

            case "screen_initial":
                return <Page_ScreenInitial CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            case "gallery":
                return <Page_Gallery CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "gallery_details":
                return <Page_Gallery_Details CkickPage={ CkickPage } setLoading={ setLoading } />;

            case "floorplans":
                return <Page_Floorplans_Details CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            case "video":
                return <Page_Video CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "video_details":
                return <Page_Video_Details CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            case "virtual_tour":
                return <Page_VirtualTour CkickPage={ CkickPage } setLoading={ setLoading } />;

            case "map_poi":
                return <Page_MapPoi CkickPage={ CkickPage } setLoading={ setLoading } />;

            case "map_2":
                return <Page_Map2 CkickPage={ CkickPage } setLoading={ setLoading } />;

            case "apartments":
                return <Page_Apartments CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "units":
                return <Page_Units CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "apartments_building":
                return <Page_Building CkickPage={ CkickPage } setLoading={ setLoading } />;
            case "apartments_details":
                return <Page_Apartments_Details CkickPage={ CkickPage } setLoading={ setLoading } />;
                
            default:
                return (
                    userAccess == 1 ?
                    <Page_ScreenInitial CkickPage={ CkickPage } setLoading={ setLoading } /> : ""
                )
        }
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    return(
        <div className="Contents">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <section className="dots-container">
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                </section>
            </div>

            <a href="" target="_blank">
                <div className="show_site">
                    <SvgSite color="#ffffff" className="icons_site" />
                </div>
            </a>

            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">

                        <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="">
                                    
                                    {
                                        status == true ?
                                        <SvgLogoTipo_2 color="#ffffff" className="logotipo2" /> :
                                        <SvgLogoTipo color="#ffffff" className="logotipo" />
                                    } 
                                </div>
                                {
                                    status == true ? null :
                                    <div className="">
                                        <SvgMenuHamburguer color="#ffffff" className="icons" />
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <div className="opt_menu_dash">
                            {
                                userAccess == 1 ?
                                <>
                                    <div className={ currentPage == "index" || currentPage == "screen_initial" || currentPage == "screen_initial_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("screen_initial"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgScreen color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Home</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "config" || currentPage == "config_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("config"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardService color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Settings</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgUserDash color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Users dashboard</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "apartments_building" || currentPage == "apartments" || currentPage == "apartments_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("apartments"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgApartments color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Apartments</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "units" || currentPage == "units_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("units"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgApartments color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Units</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "view_360_rotation" || currentPage == "view_360_rotation_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("view_360_rotation"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgRotation color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>View 360 rotation</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "view_360_floorplan" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("view_360_floorplan"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgRotation color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>View 360 floorplan</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "view_360_floorplan_points" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("view_360_floorplan_points"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgRotation color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>View 360 floorplan points</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "solar" || currentPage == "solar_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("solar"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgSun color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Solar</div>
                                        </div>
                                    </div>

                                    {/* 
                                    <div className={ currentPage == "disassemble" || currentPage == "disassemble_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("disassemble"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDisassemble color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Amenities</div>
                                        </div>
                                    </div> 
                                    */}

                                    <div className={ currentPage == "gallery" || currentPage == "gallery_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("gallery"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgGallery color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Gallery</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "floorplans" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("floorplans"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgGallery color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Floorplans</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "video" || currentPage == "video_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("video"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgVideo color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Videos</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "virtual_tour" || currentPage == "virtual_tour_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("virtual_tour"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgTour color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>VR</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "map_poi" || currentPage == "map_poi_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("map_poi"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMap color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Map and POI</div>
                                        </div>
                                    </div>

                                    {/* 
                                    <div className={ currentPage == "map_2" || currentPage == "map_2_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("map_2"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMap color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Map 2</div>
                                        </div>
                                    </div> 
                                    */}
                                </> 
                                :
                                <>
                                    {
                                        userPage.map((key, index)=>{
                                            let iconMenu   = '';
                                            switch (key.page) {
                                                case 'solar':
                                                        iconMenu = <SvgSun color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'general_tour':
                                                        iconMenu = <SvgClient color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'disassemble':
                                                        iconMenu = <SvgDisassemble color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'project':
                                                        iconMenu = <SvgProject color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'screen_initial':
                                                        iconMenu = <SvgScreen color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'gallery':
                                                        iconMenu = <SvgGallery color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'video':
                                                        iconMenu = <SvgVideo color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'virtual_tour':
                                                        iconMenu = <SvgTour color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'map_poi':
                                                        iconMenu = <SvgMap color="#FFC400" className="icons_dash" />;
                                                    break;

                                                case 'map_2':
                                                        iconMenu = <SvgMap color="#FFC400" className="icons_dash" />;
                                                    break;
                                            }
                                            
                                            let activeMenu = '';
                                            if(key.page == 'solar' && currentPage == 'solar_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'general_tour' && currentPage == 'general_tour_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'disassemble' && currentPage == 'disassemble_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'project' && currentPage == 'project_tour_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'screen_initial' && currentPage == 'screen_initial_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'gallery' && currentPage == 'gallery_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'video' && currentPage == 'video_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'virtual_tour' && currentPage == 'virtual_tour_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'map_poi' && currentPage == 'map_poi_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'map_2' && currentPage == 'map_2_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else {
                                                status == true ? activeMenu = "show_menu_dash show_menu_dash_": activeMenu = "show_menu_dash";
                                            }
                                            
                                            return(
                                                <div className={ activeMenu } onClick={ ()=>{ CkickPage(key.page); } } key={ index }>
                                                    <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                        <div className="div_icons">
                                                            { iconMenu }
                                                        </div>
                                                        <div className={ status == true ? "menu_close" : "" }>
                                                            { key.name }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }

                            <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status == true ? "menu_close" : "" }>Logout</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_profile">
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <SvgUser color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close" : "open_profile" }>
                                <SvgMenuPoint color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_EditProfile />
            <PopUp_PhotoCrop />
            <PopUP_ShowFile />
            <PopUP_ShowGallery />
            <PopUP_Confirmation />
            <PopUP_ShowFileTwo />
            <PopUP_ReturnResponse />
        </div>
    )
}
