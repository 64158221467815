import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgDelete, SvgImg, SvgPdf, SvgSave, SvgSetaRight, SvgStar } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Floorplans } from "services/RegisterData";

import PointImg from "components/PointImg";

export default function Page_Floorplans_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("floorplans"));
    const [ idPage, setIdPage ] = useState(1); // GetListPag('currentPageId')

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ file, setFile ] = useState(InitialData('file'));
    const [ status, setStatus ] = useState(false);
    const [ listImg, setListImg ] = useState(InitialData('list_img'));

    function InitialData(type){
        if(idPage !=0){
            const newData = GetDataPage("floorplans").find(item => item.id == idPage);
            console.log(newData[type]);
            return newData[type];
        }
        if(type == 'ind_floorplan'){
            return [];
        }
        if(type == 'list_img'){
            return [];
        }
        return '';
    }

    function AddCover(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, index, file, index_1){
        if(type == 'list_img'){
            SetModalData('ShowFile', { "type": "list", "listFile": listImg, "index": index, "file": file, "folder": "floorplans" });
        }else if(type == 'list_img_ind'){
            SetModalData('ShowFile', { "type": "list", "listFile": listImg[index]['list_ind'], "index": index_1, "file": file, "folder": "floorplans" });
        }else{
            SetModalData('ShowFile', { "type": type, "listFile": listImg, "index": index, "file": file, "folder": "floorplans" });
        }
        SetModalState('ShowFile', true);
    }

    /* list img */
    function DeleteData(type, id, index, index_1, value){
        if(id == 0){
            const newData = [...listImg];
            if(type == 'delete_data'){
                newData.splice(index, 1);
            }else {
                newData[index]['list_ind'].splice(index_1, 1);
            }
            setListImg(newData);

        }else {
            SetModalData('Confirmation', { "origin": "floorplans", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }
    function HandleData(origin, type, index, index_1, value){
        const newData = [...listImg];
        if(origin == 0){
            if(type == 'img'){
                if(value){
                    newData[index][type]     = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index][type]     = '';
                    newData[index]['status'] = false;
                }
            }else if(type == 'file'){
                if(value){
                    newData[index][type]     = value;
                    newData[index]['statusFile'] = true;
                }else {
                    newData[index][type]     = '';
                    newData[index]['statusFile'] = false;
                }
            }else {
                newData[index][type] = value;
            }
        }else {
            if(type == 'img'){
                if(value){
                    newData[index]['list_ind'][index_1][type]     = value;
                    newData[index]['list_ind'][index_1]['status'] = true;
                }else {
                    newData[index]['list_ind'][index_1][type]     = '';
                    newData[index]['list_ind'][index_1]['status'] = false;
                }
            }else {
                newData[index]['list_ind'][index_1][type] = value;
            }
        }
        setListImg(newData);
    }
    function AddInd(index){
        const newData = [...listImg];
        newData[index]['list_ind'].push({ "id": 0, "title": "", "img": "", "status": false });
        setListImg(newData);
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Floorplans(userId, idPage, title, text, file, listImg, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'floorplans');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterDataPage('floorplans', setDataPage);
        // RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);

            UnRegisterDataPage('floorplans', setDataPage);
            // UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("floorplans"));
        // setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setFile(InitialData('file'));
        setStatus(false);
        setListImg(InitialData('list_img'));
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Floorplans_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Floorplans
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="16" required />
                            <span className="name_input">Título</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Capa adicionada" : "Adicionar capa" }
                                    <input type="file" onChange={ (e)=>{ AddCover(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddCover(''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>                                    
                                    <div className="show_img_" onClick={ ()=>{ OpenFile("showImg", 0, status ?URL.createObjectURL(file) : file) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                </>
                                : null
                            }
                        </span>
                    </div>
                    {/* 
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div> 
                    */}
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Lista de imagens</div>
                        <div className="new_data_page" onClick={ ()=>{ setListImg([...listImg, { "id": 0, "title": "", "img": "", "status": false, "file": "", "statusFile": false, "bathrooms": "", "size": "", "flex": false, "list_ind": [] }]) } }>
                            Main Floorplan
                        </div>
                    </div>                    
                    {
                        listImg.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData(0, 'title', index, 0, e.target.value) } } value={ elem.title } maxLength="140" required />
                                        <span className="name_input">Título</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="bedrooms" onChange={ (e)=>{ HandleData(0, 'size', index, 0, e.target.value) } } value={ elem.size } maxLength="2" />
                                        <span className="name_input">Size</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="bedrooms" onChange={ (e)=>{ HandleData(0, 'bedrooms', index, 0, e.target.value) } } value={ elem.bedrooms } maxLength="2" />
                                        <span className="name_input">Bedrooms</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="bedrooms" onChange={ (e)=>{ HandleData(0, 'bathrooms', index, 0, e.target.value) } } value={ elem.bathrooms } maxLength="2" />
                                        <span className="name_input">Bathrooms</span>
                                    </div>
                                    <div className="div_input">
                                        <select onChange={ (e)=>{ HandleData(0, 'flex', index, 0, e.target.value) } } value={ elem.flex }>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                        <span className="name_input">Flex</span>
                                    </div>
                                    
                                    <span className="div_input div_add_img">
                                        <div className={ elem.statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { elem.statusFile == true ? "PDF adicionada" : "Adicionar pdf" }
                                                <input type="file" onChange={ (e)=>{ HandleData(0, 'file', index, 0, e.target.files[0]); } } accept="application/pdf" />
                                            </label>
                                        </div>
                                        {
                                            elem.file !='' ?
                                            <>
                                                <div className="delete_img_add" onClick={ ()=>{ HandleData(0, 'file', index, 0, ''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div>
                                                <div className="show_img_" onClick={ ()=>{ OpenFile("showPdf", 0, elem.statusFile ? URL.createObjectURL(elem.file) : elem.file) } }>
                                                    <SvgPdf color="#324d6b" className="icons"/>
                                                </div>
                                            </>
                                            : null
                                        }
                                    </span>

                                    <span className="div_input div_add_img">
                                        <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { elem.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                <input type="file" onChange={ (e)=>{ HandleData(0, 'img', index, 0, e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            elem.img !='' ?
                                            <>
                                                <div className="delete_img_add" onClick={ ()=>{ HandleData(0, 'img', index, 0, ''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div>
                                                {
                                                    elem.status == true ?
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile("showImg_local", 0, URL.createObjectURL(elem.img)) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div> :
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile("list_img", index, '') } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                            </>
                                            : null
                                        }
                                    </span>
                                    <div className="div_input new_data_page" onClick={ ()=>{ AddInd(index) } }>
                                        Add img individual
                                    </div>
                                    <div className="div_input delete_img" onClick={ ()=>{ DeleteData('delete_data', elem.id, index, 0, elem.title) } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                    
                                    {
                                        elem.list_ind.length > 0 ?
                                        <div className="list_div_ind">
                                            {
                                                elem.list_ind.map((elem_1, index_1)=>{
                                                    return(                                                    
                                                        <div className="list_input_data border_top" key={ index_1 }>
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleData(1, 'title', index, index_1, e.target.value) } } value={ elem_1.title } maxLength="140" required />
                                                                <span className="name_input">Título</span>
                                                            </div>
                                                            <span className="div_input div_add_img">
                                                                <div className={ elem_1.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                    <label>
                                                                        { elem_1.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                                        <input type="file" onChange={ (e)=>{ HandleData(1, 'img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                                                    </label>
                                                                </div>
                                                                {
                                                                    elem_1.img !='' ?
                                                                    <>
                                                                        <div className="delete_img_add" onClick={ ()=>{ HandleData(1, 'img', index, index_1, ''); } }>
                                                                            <SvgDelete className="icons" color="#ffffff" />
                                                                        </div>
                                                                        {
                                                                            elem_1.status == true ?
                                                                            <div className="show_img_" onClick={ ()=>{ OpenFile("showImg_local", 0, URL.createObjectURL(elem_1.img)) } }>
                                                                                <SvgImg color="#324d6b" className="icons"/>
                                                                            </div> :
                                                                            <div className="show_img_" onClick={ ()=>{ OpenFile("list_img_ind", index, '', index_1) } }>
                                                                                <SvgImg color="#324d6b" className="icons"/>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    : null
                                                                }
                                                            </span>
                                                            <div className="div_input delete_img" onClick={ ()=>{ DeleteData('delete_ind', elem_1.id, index, index_1, elem_1.title) } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}