const ModalState = {
    Crop           : false,
    Profile        : false,
    ShowFile       : false,
    ShowFileTwo    : false,
    ShowGallery    : false,
    GalleryBuilding : false,
    GalleryBuildingPoint : false,
    GeneralTourPoint : false,
    Confirmation   : false,
    ReturnResponse : false,
    OpenData       : false,
    AddPointMap    : false,
    SizeIcon       : false,
    SelectedPointCanvas : false,
    Color: false
};

const ModalData = {
    Crop           : {},
    Profile        : {},
    ShowFile       : {},
    ShowFileTwo    : {},
    ShowGallery    : {},
    GalleryBuilding : {},
    GalleryBuildingPoint : {},
    GeneralTourPoint : {},
    Confirmation   : {},
    ReturnResponse : {},
    OpenData       : {},
    AddPointMap    : {},
    SizeIcon       : {},
    SelectedPointCanvas : {},
    Color: {}
}

const NotifyModalObserver = {
    Crop           : [],
    Profile        : [],
    ShowFile       : [],
    ShowFileTwo    : [],
    ShowGallery    : [],
    GalleryBuilding : [],
    GalleryBuildingPoint : [],
    GeneralTourPoint : [],
    Confirmation   : [],
    ReturnResponse : [],
    OpenData       : [],
    AddPointMap    : [],
    SizeIcon       : [],
    SelectedPointCanvas : [],
    Color: []
}

const NotifyModalData = {
    Crop           : [],
    Profile        : [],
    ShowFile       : [],
    ShowFileTwo    : [],
    ShowGallery    : [],
    GalleryBuilding : [],
    GalleryBuildingPoint : [],
    GeneralTourPoint : [],
    Confirmation   : [],
    ReturnResponse : [],
    OpenData       : [],
    AddPointMap    : [],
    SizeIcon       : [],
    SelectedPointCanvas : [],
    Color: []
}

// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key, value){
    return ModalData[key][value];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}

// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
