import { useState, useEffect } from "react";

import './Details.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgStar } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { Reg_Building } from "services/RegisterData";
import PopUP_GalleryBuilding from "components/PopUp/GalleryBuilding";
import PopUP_GalleryBuildingPoint from "components/PopUp/GalleryBuildingPoint";

export default function Page_Building(props){

    const [ typeData, setTypeData ]   = useState('desktop');
    const [ userId, setUserId ]       = useState(GetUserData('id'));
    const [ dataPage, setDataPage ]   = useState(GetDataPage("apartments_file"));
    const [ dataSide, setDataSide ]   = useState(GetDataPage('apartments_side'));
    const [ dataTower, setDataTower ] = useState(GetDataPage('apartments_tower'));
    const [ dataPoint, setDataPoint ] = useState(GetDataPage("list_point"));

    const [ imgDesktop, setImgDesktop ]                 = useState(InitialData('desktop'));
    const [ comprDesktop, setComprDesktop ]             = useState([]);
    const [ statusComprDesktop, setStatusComprDesktop ] = useState(false);
    
    const [ imgMobile, setImgMobile ]                 = useState(InitialData('mobile'));
    const [ comprMobile, setComprMobile ]             = useState([]);
    const [ statusComprMobile, setStatusComprMobile ] = useState(false);

    const [ tower, setTower ] = useState(GetDataPage('apartments_tower'));
    const [ listPoint, setListPoint ] = useState(GetDataPage("list_point"));

    function InitialData(type){
        const newData = dataPage.filter(item => item.type == type);
        return newData;
    }

    function AddComprDesktop(value){
        if(value){
            setComprDesktop(value);
            setStatusComprDesktop(true);
        }else {
            setComprDesktop('');
            setStatusComprDesktop(false);
        }
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "apartments_file", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function OpenMask(type, file){
        SetModalData('ShowFile', { "typeFile": "only", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function OpenFile(idPage, index, img){
        SetModalData('GalleryBuilding', { "idPage": idPage, "img": img, "index": index });
        SetModalState('GalleryBuilding', true);  
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Building(userId, comprDesktop, comprMobile, tower, listPoint, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'apartments');
        SetListPag('currentPageId', 'remuve');
    }

    function HandlePoint(origin, type, index, index_1, value){
        const newData = [...listPoint];
        if(origin == 0){
            newData[index][type] = value;
        }else {
            if(type == 'img'){
                if(value){
                    newData[index]['point'][index_1]['img'] = value;
                    newData[index]['point'][index_1]['imgStatus'] = true;
                }else {
                    newData[index]['point'][index_1]['img'] = '';
                    newData[index]['point'][index_1]['imgStatus'] = false;
                }

            }else if(type == 'mask'){
                if(value){
                    newData[index]['point'][index_1]['mask'] = value;
                    newData[index]['point'][index_1]['maskStatus'] = true;
                }else {
                    newData[index]['point'][index_1]['mask'] = '';
                    newData[index]['point'][index_1]['maskStatus'] = false;
                }

            }else {
                newData[index]['point'][index_1][type] = value;
            }
        }
        setListPoint(newData);
    }

    /* tower */
    function DeleteTower(type, id, index, index_1, value){
        if(id == 0){
            const newData = [...tower];
            if(type == "delete_tower"){
                newData.splice(index, 1);
            }else {                
                newData[index]['side'].splice(index_1, 1);
            }
            setTower(newData);

        }else {
            SetModalData('Confirmation', { "origin": "building", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }
    function NewSide(index){
        const newData = [...tower];
        newData[index].side.push({ "id": 0, "name": "", "img": "", "statusImg": false, "mask": "", "statusMask": false });
        setTower(newData);
    }
    function HandleTower(origin, type, index, index_1, value){
        const newData = [...tower];
        if(origin == 0){
            newData[index][type] = value;
        }else {
            if(type == 'img'){
                if(value){
                    newData[index]['side'][index_1]['img'] = value;
                    newData[index]['side'][index_1]['statusImg'] = true;
                }else {
                    newData[index]['side'][index_1]['img'] = '';
                    newData[index]['side'][index_1]['statusImg'] = false;
                }

            }else if(type == 'mask'){
                if(value){
                    newData[index]['side'][index_1]['mask'] = value;
                    newData[index]['side'][index_1]['statusMask'] = true;
                }else {
                    newData[index]['side'][index_1]['mask'] = '';
                    newData[index]['side'][index_1]['statusMask'] = false;
                }

            }else {
                newData[index]['side'][index_1][type] = value;
            }
        }
        setTower(newData);
    }
    /* end */

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('apartments_file', setDataPage);
        RegisterDataPage('apartments_side', setDataSide);
        RegisterDataPage('apartments_tower', setDataTower);
        RegisterDataPage('list_point', setListPoint);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('apartments_file', setDataPage);
            UnRegisterDataPage('apartments_side', setDataSide);
            UnRegisterDataPage('apartments_tower', setDataTower);
            UnRegisterDataPage('list_point', setListPoint);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("apartments_file"));
        setDataSide(GetDataPage("apartments_side"));
        setDataTower(GetDataPage("apartments_tower"));
        setDataPoint(GetDataPage("list_point"));

        setImgDesktop(InitialData('desktop'));
        setComprDesktop([]);
        setStatusComprDesktop(false);

        setImgMobile(InitialData('mobile'));
        setComprMobile([]);
        setStatusComprMobile(false);

        setTower(GetDataPage('apartments_tower'));
        setListPoint(GetDataPage("list_point"));
    }, [dataPage]);

    useEffect(()=>{
        setDataPoint(GetDataPage("list_point"));
        setListPoint(GetDataPage("list_point"));
    }, [dataPoint]);
    
    return(
        <div className="Page_Building">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Fotos do prédio
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Tower / Side</div>
                        <div className="new_data_page" onClick={ ()=>{ setTower([...tower, { "id": 0, "name": "", "side": [] }]) } }>
                            New tower
                        </div>
                    </div>
                    {
                        tower.map((elem, index)=>{
                            
                            return(
                                <div className="list_point_div list_div_tower" key={ index }>
                                    <div className="name_block">Tower 0{ index + 1 }</div>

                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="" onChange={ (e)=>{ HandleTower(0, 'name', index, 0, e.target.value) } } value={ elem.name } />
                                            <span className="name_input">Name tower</span>
                                        </div>
                                        <div className="div_input" onClick={ ()=>{ DeleteTower('delete_tower', elem.id, index, 0, elem.name) } }>
                                            <SvgDelete className="icons" color="#f00000" />
                                        </div>
                                    </div>
                                    <div className="list_type_data">
                                        <div className="type_title">Side</div>
                                        <div className="new_data_page" onClick={ ()=>{ NewSide(index) } }>
                                            New side
                                        </div>
                                    </div>
                                    {
                                        elem.side.map((elem_1, index_1)=>{

                                            return(
                                                <div className="list_input_data border_top" key={ index_1 }>
                                                    <div className="div_input space_div">
                                                        <input type="text" className="" onChange={ (e)=>{ HandleTower(1, 'name', index, index_1, e.target.value) } } value={ elem_1.name } />
                                                        <span className="name_input">Name side</span>
                                                    </div>

                                                    <span className="div_input div_add_img">
                                                        <div className={ elem_1.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { elem_1.statusImg == true ? "Foto adicionada" : "Adicionar foto" }
                                                                <input type="file" onChange={ (e)=>{ HandleTower(1, 'img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                        {
                                                            elem_1.img !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleTower(1, 'img', index, index_1, ''); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div>
                                                                {
                                                                    elem_1.statusImg == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenMask('showImg_local', URL.createObjectURL(elem_1.img)) } }>
                                                                        <img alt={ "img_" + index_1 } src={ URL.createObjectURL(elem_1.img) } className="icons" />
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenMask('showImg', elem_1.img) } }>
                                                                        <img alt={ "img_" + index_1 } src={ elem_1.img } className="icons" />
                                                                    </div>
                                                                }
                                                            </>
                                                            : null
                                                        }
                                                    </span>

                                                    <span className="div_input div_add_img">
                                                        <div className={ elem_1.statusMask == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { elem_1.statusMask == true ? "Máscara adicionada" : "Adicionar máscara" }
                                                                <input type="file" onChange={ (e)=>{ HandleTower(1, 'mask', index, index_1, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                        {
                                                            elem_1.mask !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleTower(1, 'mask', index, index_1, ''); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div>
                                                                {
                                                                    elem_1.statusMask == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenMask('showImg_local', URL.createObjectURL(elem_1.mask)) } }>
                                                                        <img alt={ "img_" + index_1 } src={ URL.createObjectURL(elem_1.mask) } className="icons" />
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenMask('showImg', elem_1.mask) } }>
                                                                        <img alt={ "img_" + index_1 } src={ elem_1.mask } className="icons" />
                                                                    </div>
                                                                }
                                                            </>
                                                            : null
                                                        }
                                                    </span>

                                                    <div className="div_input" onClick={ ()=>{ DeleteTower('delete_side', elem.id, index, index_1, elem.name) } }>
                                                        <SvgDelete className="icons" color="#f00000" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                {
                    listPoint.length > 0 ? 
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Lista dos pontos</div>
                        </div>
                        {
                            listPoint.map((elem, index)=>{
                                return(
                                    <div className="list_input_data list_point_div" key={ index }>
                                        <div className="div_img measure_div_">
                                            <img alt="img" src={ elem.img } className="img " />
                                            {                                                    
                                                elem.point.map((elem_1, index_1)=>{
                                                    let positionY = 327 * elem_1.y;
                                                    let positionX = 580 * elem_1.x;

                                                    return(
                                                        <div className="point_selected" key={ index_1 } style={ { top: positionY + "px", left: positionX + "px" } }>
                                                            <div className="show_number_point">
                                                                { index_1 + 1 }
                                                            </div>
                                                        </div>
                                                    )                                                    
                                                })
                                            }
                                        </div>
                                        <div className="div_point">
                                            <div className="point_position">
                                                <div className="show_number_point">
                                                    Tower
                                                </div>
                                                <div className="div_input space_div">
                                                    <select onChange={ (e)=>{ HandlePoint(0, 'tower', index, 0, e.target.value) } } value={ elem.tower }>
                                                        <option value="">#</option>
                                                        {
                                                            tower.map((elem_2, index_2)=>{
                                                                return(
                                                                    <option value={ elem_2.id } key={ index_2 }>{ elem_2.name }</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <span className="name_input">Tower</span>
                                                </div>
                                            </div>

                                            {
                                                elem.point.map((elem_1, index_1)=>{
                                                    return(
                                                        <div className="point_position" key={ index_1 }>
                                                            <div className="show_number_point">
                                                                Posição { index_1 + 1 }
                                                            </div>

                                                            <div className="div_input space_div">
                                                                <select onChange={ (e)=>{ HandlePoint(1, 'side', index, index_1, e.target.value) } } value={ elem_1.side }>
                                                                    <option value="">#</option>
                                                                    {
                                                                        tower.map((elem_2, index_2)=>{
                                                                            if(elem_2.id == parseInt(elem.tower)){
                                                                                return(
                                                                                    elem_2.side.map((elem_3, index_3)=>{
                                                                                        return(
                                                                                            <option value={ elem_3.id } key={ index_3 }>{ elem_3.name }</option>
                                                                                        )
                                                                                    })
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </select>
                                                                <span className="name_input">Side</span>
                                                            </div>
                                                            <div className="div_input" onClick={ ()=>{ DeleteTower('delete_point', elem_1.id, index, index_1, "Posição " +  (index_1 + 1)) } }>
                                                                <SvgDelete className="icons" color="#f00000" />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null
                }
                
                <div className="show_page_data">
                    <div className="list_input_data show_list_file">
                        <div className="space_div div_add_img">
                            {
                                imgDesktop.length == 0 ? null :
                                <div className="delete_all" onClick={ ()=>{ DeleteData('delete_all_desktop', "Todas as imagens") } }>
                                    Deletar todas as imagens
                                </div>
                            }

                            <div className={ statusComprDesktop == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusComprDesktop == true ? "Imagens adicionadas" : "Adicionar imagem" }
                                    <input type="file" onChange={ (e)=>{ AddComprDesktop(e.target.files); } } accept="image/*" multiple />
                                </label>
                            </div>
                            {
                                comprDesktop == '' ? null :
                                <div className="delete_img_add" onClick={ ()=>{ AddComprDesktop(''); } }>
                                    <SvgDelete className="icons" color="#ffffff" />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="list_input_data">
                        {
                            imgDesktop.map((elem, index)=>{
                                return(
                                    <div className="div_list_file" key={ index }>
                                        <div className="numb_img">{ index + 1 }</div>
                                        <div className="name_img">
                                            { elem.img }
                                            {
                                                elem.imgFixed ? 
                                                <div data-tooltip-id={ "type_data_" + elem.id } data-tooltip-content="Imagem fixada" data-tooltip-place="top">
                                                    <SvgStar className="icons" color="#0b711c" />
                                                </div> : null 
                                            }
                                            <Tooltip id={ "type_data_" + elem.id } />
                                        </div>
                                        <div className="delete_img">
                                            <div className="show_img_" onClick={ ()=>{ OpenFile(elem.id, index, elem.img) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div>
                                        </div>
                                        <div className="delete_img" onClick={ ()=>{ DeleteData('delete_building', elem.id, elem.img) } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        }                             
                    </div>
                </div>
            </form>
            <PopUP_GalleryBuilding />
            <PopUP_GalleryBuildingPoint />
        </div>
    )
}