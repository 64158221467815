import { useState, useEffect } from "react";

import './View360Floorplan.css';

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_View360Floorplan } from "services/RegisterData";
import PopUP_GalleryBuilding from "components/PopUp/GalleryBuilding";

export default function Page_View360Floorplan(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("view_360_floorplan"));

    const [ listImg, setListImg ] = useState(GetDataPage("view_360_floorplan"));   
    const [ fileCompr, setFileCompr ] = useState([]);
    const [ statusFileCompr, setStatusFileCompr ] = useState(false);

    function AddFileCompr(value){
        if(value){
            setFileCompr(value);
            setStatusFileCompr(true);
        }else {
            setFileCompr('');
            setStatusFileCompr(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function OpenGallery(id, index, mask){        
        SetModalData('GalleryBuilding', { "idImg": id, "mask": mask, "index": index, "listImg": listImg });
        SetModalState('GalleryBuilding', true);  
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "view_360_floorplan", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_View360Floorplan(userId, fileCompr, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterDataPage('view_360_floorplan', setDataPage);
        return ()=>{
            UnRegisterDataPage('view_360_floorplan', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("view_360_floorplan"));
        setListImg(GetDataPage("view_360_floorplan"));   
        setFileCompr([]);
        setStatusFileCompr(false);
    }, [dataPage]);
    
    return(
        <div className="Page_View360Floorplan">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_fixed name_page">
                            View 360 Floorplan
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                
                <div className="show_page_data">
                    <div className="list_input_data show_list_file">
                        <div className="space_div div_add_img">
                            {
                                listImg.length == 0 ? null :
                                <div className="delete_all" onClick={ ()=>{ DeleteData('delete_all', 0, "Todas as imagens") } }>
                                    Deletar todas as imagens
                                </div>
                            }

                            <div className={ statusFileCompr == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusFileCompr == true ? "Imagens adicionadas" : "Adicionar imagem" }
                                    <input type="file" onChange={ (e)=>{ AddFileCompr(e.target.files); } } accept="image/*" multiple />
                                </label>
                            </div>

                            {
                                fileCompr == '' ? null :
                                <div className="delete_img_add" onClick={ ()=>{ AddFileCompr(''); } }>
                                    <SvgDelete className="icons" color="#ffffff" />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="list_input_data">
                        {
                            listImg.map((elem, index)=>{
                                let nameImg = elem.img.split('/');
                                return(
                                    <div className="div_list_file" key={ index }>
                                        <div className="numb_img">{ index + 1 }</div>
                                        <div className="name_img">
                                            { nameImg[4] }
                                            {
                                                elem.mask !='' ? 
                                                <img alt={ "img_" + index } src={ elem.mask } className="show_mask" onClick={ ()=>{  OpenFile('showImg', elem.mask) } } />
                                                : null
                                            }
                                        </div>
                                        <div className="delete_img">
                                            <div className="show_img_" onClick={ ()=>{ OpenGallery(elem.id, index, elem.mask) } }>
                                                <SvgImg className="icons" color="#324d6b" />
                                            </div>
                                        </div>                                                 
                                        <div className="delete_img" onClick={ ()=>{ DeleteData('delete_img', elem.id, elem.img) } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>  
                                    </div>
                                )
                            })
                        }                             
                    </div>
                </div>
            </form>
            <PopUP_GalleryBuilding setLoading={ props.setLoading } />
        </div>
    )
}