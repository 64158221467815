import React, { useState, useEffect, useRef } from "react";

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import './GeneralTourPoint.css';

import { AddListDataSinglePoint, CleanDataPoint, GetDataPagePoint, RegisterDataPagePoint, UnRegisterDataPagePoint } from "interface/DataPoint";

import { Reg_PointTour } from "services/RegisterData";

export default function PopUP_GeneralTourPoint(){

    const pointClick  = useRef();
    const pointImgAdd = useRef();

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    
    const [ showImg, setShowImg ]     = useState('');
    const [ showIndex, setShowIndex ] = useState(0);

    const [ imgFixed, setImgFixed ] = useState('');
    const [ idFixed, setIdFixed ] = useState(0);
    const [ imgList, setImgList ] = useState([]);
    const [ point, setPoint ] = useState({ "top": 0, "left": 0 });
    const [ historicPoint, setHistoricPoint ] = useState({ "top": 0, "left": 0 });
    const [ statusPoint, setStatusPoint ] = useState(false);

    function ClosePopUp(){
        setImgFixed('');
        setPoint({ "top": 0, "left": 0 });
        setHistoricPoint({ "top": 0, "left": 0 });
        setStatusPoint(false);
        SetModalState('GeneralTourPoint', false);
    }

    function SavePoint(){
        modalData.SavePointClick({
            "index": modalData.index,
            "id_img": modalData.id_img,
            "show_img": imgFixed,
            "type": modalData.type,
            "id": modalData.idPage,
            "imgFixed": imgFixed,
            "idFixed": idFixed,
            "left": point.left,
            "top": point.top
        });
        ClosePopUp();
    }

    useEffect(()=>{
        RegisterModalData('GeneralTourPoint', setModaldata);
        RegisterModalObserver('GeneralTourPoint', setShowPopUp);
        return ()=>{ };
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            // RegisterDataPagePoint("general_tour_point", RegisterPoint);

            setImgFixed(modalData.img);
            setImgList(modalData.listImg);
            setIdFixed(modalData.id_img);

            setPoint({ "top": modalData.top, "left": modalData.left });
            setHistoricPoint({ "top": modalData.top, "left": modalData.left });

            pointClick.current.addEventListener('click', (elem)=>{
                setPoint({ "top": elem.offsetY, "left": elem.offsetX });
                setStatusPoint(true);

                // AddListDataSinglePoint("general_tour_point", { "id": 0, "imgPoint": pointImgAdd.current.currentSrc, "clientWidth": elem.target.clientWidth, "clientHeight": elem.target.clientHeight, "top": elem.offsetY, "left": elem.offsetX });

            });
        }else {
            // UnRegisterDataPagePoint("general_tour_point", RegisterPoint);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all general_tour_point" style={ { backgroundColor: '#2d3c53' } }>
                        <div className="div_data type_div">
                            <div className="title"></div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {
                                    imgFixed !='' ?
                                    <div className="add_point">
                                        {
                                            statusPoint ? 
                                            <>
                                                Para adicionar um ponto basta clicar no local
                                                <div className="btn" onClick={ ()=>{ setStatusPoint(false); setPoint(historicPoint) } }>
                                                    Cancelar ponto
                                                </div>
                                                <div className="btn save_point" onClick={ ()=>{ SavePoint(); } }>
                                                    Salvar pontos
                                                </div>
                                            </> :                                             
                                            <div className="btn" onClick={ ()=>{ setStatusPoint(true); } }>
                                                {
                                                    point.top == 0 ? "Adicionar ponto" : "Alterar ponto"
                                                }
                                            </div>
                                        }
                                    </div> :
                                    <div className="div_list_img">
                                        {
                                            imgList.map((elem, index)=>{
                                                let id_left = 0;
                                                if(modalData.showPointClick.find(item => item.left_id_img == elem.id)){
                                                    id_left = modalData.showPointClick.find(item => item.left_id_img == elem.id).left_id_img;
                                                }

                                                let id_center = 0;
                                                if(modalData.showPointClick.find(item => item.center_id_img == elem.id)){
                                                    id_center = modalData.showPointClick.find(item => item.center_id_img == elem.id).center_id_img;
                                                }

                                                let id_right = 0;
                                                if(modalData.showPointClick.find(item => item.right_id_img == elem.id)){
                                                    id_right = modalData.showPointClick.find(item => item.right_id_img == elem.id).right_id_img;
                                                }

                                                return(
                                                    <div className="div_img" key={ index } onClick={ ()=>{ setImgFixed(elem.img); setIdFixed(elem.id) } }>
                                                        {
                                                            id_left == elem.id || id_center == elem.id || id_right == elem.id ? 
                                                            <div className="img_used">
                                                                Imagem utilizada
                                                            </div> : null
                                                        }
                                                        <img alt="img" src={ elem.thumbnail } className="img" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <div className={ imgFixed !='' ? "div_img" : "div_img display_none" }>
                                    <div className="div_square" ref={ pointClick }>

                                        <div className="point_selected" style={ { top: point.top + "px", left: point.left + "px" } }>
                                            <div className="show_number_point" />
                                        </div>

                                    </div>
                                    
                                    <img ref={ pointImgAdd } alt="img" src={ imgFixed } className="show_img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        statusPoint == false ? <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } /> : null
                    }
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
