import React, { useState, useEffect, useRef } from "react";

import { SvgClose, SvgSetaRight } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import './GalleryBuilding.css';

import { GetDataPage } from "interface/Data";

import { AddListDataSinglePoint, CleanDataPoint, GetDataPagePoint, RegisterDataPagePoint, UnRegisterDataPagePoint } from "interface/DataPoint";

import { Reg_Point, Reg_View360FloorplanAltMask } from "services/RegisterData";

export default function PopUP_GalleryBuilding(props){
    
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ imgIndex, setImgIndex ] = useState(0);
    const [ listImg, setListImg ] = useState([]);
    const [ mask, setMask ] = useState('');
    const [ maskStatus, setMaskStatus ] = useState(false);
    const [ statusFile, setStatusFile ] = useState(false);

    function ClosePopUp(){
        setImgIndex(0);
        setListImg([]);
        setMask('');
        setMaskStatus(false);
        setStatusFile(false);

        CleanDataPoint("data_building");
        SetModalState('GalleryBuilding', false);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(value){
        if(value){
            setMask(value);
            setMaskStatus(true);
        }else {
            setMask('');
            setMaskStatus(false);
        }

        if(modalData.mask == value){
            setStatusFile(false);
        }else {
            setStatusFile(true);
        }
    }

    function SavePoint(){
        props.setLoading(true);
        Reg_View360FloorplanAltMask(listImg[imgIndex].id, mask, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterModalData('GalleryBuilding', setModaldata);
        RegisterModalObserver('GalleryBuilding', setShowPopUp);
        return ()=>{ };
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setImgIndex(modalData.index);
            setListImg(modalData.listImg);
            setMask(modalData.mask);
            setMaskStatus(false);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp AlignFile">
                    <div className="all gallery_building" style={ { backgroundColor: '#2d3c53' } }>
                        {/* <div className="div_arrow">
                            {
                                idImg - 1 >= 0 ?
                                <div className="arrow" onClick={ ()=>{ setIdImg(idImg - 1) } }>
                                    <SvgSetaRight className="icons icons_left" color="#ffffff" />
                                </div> : 
                                <div className="arrow">
                                    <SvgSetaRight className="icons icons_left" color="#535353" />
                                </div>
                            }
                            <div className="space_white" />
                            {
                                idImg < showImg.length - 1  ?
                                <div className="arrow" onClick={ ()=>{ setIdImg(idImg + 1) } }>
                                    <SvgSetaRight className="icons" color="#ffffff" />
                                </div> : 
                                <div className="arrow">
                                    <SvgSetaRight className="icons" color="#535353" />
                                </div>
                            }
                        </div> */}

                        <div className="div_data type_div">
                            <div className="title"></div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="add_point">
                                    
                                    {
                                        mask ? 
                                        <>
                                            <div className="btn" onClick={ ()=>{ AddFile(''); } }>Remover máscara</div>
                                            <div className="" onClick={ ()=>{ OpenFile('showImg', maskStatus ? URL.createObjectURL(mask) : mask) } }>
                                                <img alt="mask" src={ maskStatus ? URL.createObjectURL(mask) : mask } className="show_mask" />
                                            </div>
                                        </>
                                        : 
                                        <label>
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } />
                                            <div className="btn">Adicionar máscara</div>
                                        </label>
                                    }
                                    {
                                        statusFile ?
                                        <div className="btn save_point" onClick={ ()=>{ SavePoint(); } }>
                                            Salvar máscara
                                        </div> : null
                                    }
                                </div>
                                {
                                    listImg.length > 0 ?
                                    <div className="div_img">
                                        <img alt="img" src={ listImg[imgIndex].img } className="show_img" />
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
