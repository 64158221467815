import React, { useState, useEffect } from "react";

import './GalleryBuildingPoint.css';

import { SvgClose, SvgDelete, SvgImg } from "components/SvgFile";

import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

export default function PopUP_GalleryBuildingPoint(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ img, setImg ]                 = useState('');
    const [ statusImg, setStatusImg ]     = useState(false);
    const [ mask, setMask ]               = useState('');
    const [ statusMask, setStatusMask ]   = useState(false);

    function SaveData(){
        // Reg_EditUser(id, name, email, pass, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('GalleryBuildingPoint', false);
    }

    function AddFile(type, value){
        // img
        if(type == 'img'){
            if(value){
                setImg(value);
                setStatusImg(true);
            }else {
                setImg('');
                setStatusImg(false);
            }
        }

        // mask
        if(type == 'mask'){
            if(value){
                setMask(value);
                setStatusMask(true);
            }else {
                setMask('');
                setStatusMask(false);
            }
        }
    }

    useEffect(()=>{
        RegisterModalData('GalleryBuildingPoint', setModaldata);
        RegisterModalObserver('GalleryBuildingPoint', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_profile">
                        <div className="div_data type_div">
                            <div className="title">
                                Dados do ponto adicionado
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">                                
                                <div className="list_input_data">
                                    <span className="div_input div_add_img">
                                        <div className={ statusMask == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { statusMask == true ? "Máscara adicionada" : "Adicionar máscara" }
                                                <input type="file" onChange={ (e)=>{ AddFile('mask', e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            mask !='' ?
                                            <div className="delete_img_add" onClick={ ()=>{ AddFile('mask', ''); } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                            : null
                                        }
                                    </span>
                                </div>

                                <div className="register_data button_profile" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
