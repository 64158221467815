import { useState, useEffect } from "react";

import './View360FloorplanPoints.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgStar } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import PopUP_GeneralTourPoint from "components/PopUp/GeneralTourPoint";

import { Reg_View360FloorplanPoints } from "services/RegisterData";

export default function Page_View360FloorplanPoints(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("view_360_floorplan_points"));

    const [ listImg, setListImg ] = useState(GetDataPage("view_360_floorplan_points").list_img);   
    const [ fileCompr, setFileCompr ] = useState([]);
    const [ statusFileCompr, setStatusFileCompr ] = useState(false);
    const [ fileZip, setFileZip ] = useState('');
    const [ statusFileZip, setStatusFileZip ] = useState(false);

    const [ showPointClick, setShowPointClick ] = useState(GetDataPage("view_360_floorplan_points").point_click);
    const [ listOptData, setListOptData ] = useState(GetDataPage("view_360_floorplan_points").point_click_data);

    function AddFileCompr(type, value){
        if(type == 'img'){
            if(value){
                setFileCompr(value);
                setStatusFileCompr(true);
            }else {
                setFileCompr('');
                setStatusFileCompr(false);
            }
        }
        if(type == 'zip'){
            if(value){
                setFileZip(value);
                setStatusFileZip(true);
            }else {
                setFileZip('');
                setStatusFileZip(false);
            }
        }
    }

    function OpenGallery(index, file){
        SetModalData('ShowFile', { "type": "list", "listFile": listImg, "index": index, "file": file, "folder": "" });
        SetModalState('ShowFile', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "view_360_floorplan_points", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    /* point */
    function DeletePointData(id, index, value){
        if(id == 0){
            const newData = [...showPointClick];
            newData.splice(index, 1);
            setShowPointClick(newData);
        }else {
            SetModalData('Confirmation', { "origin": "view_360_floorplan_points", "type" : "delete_point_data", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }
    function SavePointClick(elem){
        const newData = [...showPointClick];
        if(elem.type == 'left'){
            newData[elem.index]['left_id_img'] = elem.idFixed;
            newData[elem.index]['left_img'] = elem.show_img;            
            newData[elem.index]['leftX'] = elem.left;
            newData[elem.index]['leftY'] = elem.top;
        }
        if(elem.type == 'center'){
            newData[elem.index]['center_id_img'] = elem.idFixed;
            newData[elem.index]['center_img'] = elem.show_img;       
            newData[elem.index]['centerX'] = elem.left;
            newData[elem.index]['centerY'] = elem.top;
        }
        if(elem.type == 'right'){
            newData[elem.index]['right_id_img'] = elem.idFixed;
            newData[elem.index]['right_img'] = elem.show_img;       
            newData[elem.index]['rightX'] = elem.left;
            newData[elem.index]['rightY'] = elem.top;
        }
        setShowPointClick(newData);
    }
    function OpenFilePoint(type, idPage, index, img, id_img, listImg, left, top){
        SetModalData('GeneralTourPoint', { "idPage": idPage, "type": type, "id_img": id_img, "showPointClick": showPointClick, "img": img, "index": index, "listImg": listImg, "SavePointClick": SavePointClick, "left": left, "top": top });
        SetModalState('GeneralTourPoint', true);  
    }
    function HandleDataPoint(type, index, value){
        const newData = [...showPointClick];
        if(type == 'img'){
            if(value){
                newData[index]['img'] = value;
                newData[index]['statusImg'] = true;
            }else {
                newData[index]['img'] = '';
                newData[index]['statusImg'] = false;
            }
        }else if(type == 'mask'){
            if(value){
                newData[index]['mask'] = value;
                newData[index]['statusMask'] = true;
            }else {
                newData[index]['mask'] = '';
                newData[index]['statusMask'] = false;
            }
        }else{
            newData[index][type] = value;
        }
        setShowPointClick(newData);
    }
    /* end*/

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_View360FloorplanPoints(userId, fileCompr, fileZip, showPointClick, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('view_360_floorplan_points', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('view_360_floorplan_points', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("view_360_floorplan_points"));

        setListImg(GetDataPage("view_360_floorplan_points").list_img);   
        setFileCompr([]);
        setStatusFileCompr(false);

        setFileZip('');
        setStatusFileZip(false);

        setShowPointClick(GetDataPage("view_360_floorplan_points").point_click);
        setListOptData(GetDataPage("view_360_floorplan_points").point_click_data);
    }, [dataPage]);

    // useEffect(()=>{
    //     setPointClick(GetDataPage("point_click"));
    //     setShowPointClick(GetDataPage("point_click"));
    //     setListOptData(GetDataPage("point_click_data"));
    // }, [pointClick]);
    
    return(
        <div className="Page_View360FloorplanPoints">
            <form onSubmit={ SaveData } encType="multipart/form-data">
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_fixed page_return name_page">
                            View 360 Floorplan Points
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Pontos</div>
                        <div className="new_data_page" onClick={ ()=>{ setShowPointClick([...showPointClick, { "id": 0, "title": "", "width": "", "height": "", "left_id_img": 0, "leftX": 0, "leftY": 0, "left_img": "", "center_id_img": 0, "centerX": 0, "centerY": 0, "center_img": "", "right_id_img": 0, "rightX": 0, "rightY": 0, "right_img": "", "tower": "", "new_tower": "", "side": "", "new_side": "", "img": "", "statusImg": false, "mask": "", "statusMask": false }]) } }>
                            Adicionar ponto
                        </div>
                    </div>
                    {
                        showPointClick.length > 0 ?
                        showPointClick.map((elem, index)=>{                            
                            return(
                                <div className="list_point_div" key={ index }>
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleDataPoint('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                            <span className="name_input">Título</span>
                                        </div>
                                        <div className="div_input div_add_img">
                                            <div className="new_file_add" onClick={ ()=>{ OpenFilePoint('left', elem.id, index, elem.left_img, elem.left_id_img, listImg, elem.leftX, elem.leftY) } }>
                                                {
                                                    elem.left_img == "" ? "Adicionar ponto esquerdo" : "Visualizar ponto esquerdo"
                                                }
                                            </div>
                                            {
                                                elem.left_img == "" ? null :
                                                <>                                                    
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleDataPoint('left_img', index, ''); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', elem.left_img) } }>
                                                        <img alt={ "img_left_" + index } src={ elem.left_img } className="show_img_point" />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="div_input div_add_img">
                                            <div className="new_file_add" onClick={ ()=>{ OpenFilePoint('center', elem.id, index, elem.center_img, elem.center_id_img, listImg, elem.centerX, elem.centerY) } }>
                                                {
                                                    elem.center_img == "" ? "Adicionar ponto centralizado" : "Visualizar ponto centralizado"
                                                }
                                            </div>
                                            {
                                                elem.center_img == "" ? null :
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleDataPoint('center_img', index, ''); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', elem.center_img) } }>
                                                        <img alt={ "img_center_" + index } src={ elem.center_img } className="show_img_point" />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="div_input div_add_img">
                                            <div className="new_file_add" onClick={ ()=>{ OpenFilePoint('right', elem.id, index, elem.right_img, elem.right_id_img, listImg, elem.rightX, elem.rightY) } }>
                                                {
                                                    elem.right_img == "" ? "Adicionar ponto direito" : "Visualizar ponto direito"
                                                }
                                            </div>
                                            {
                                                elem.right_img == "" ? null :
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleDataPoint('right_img', index, ''); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', elem.right_img) } }>
                                                        <img alt={ "img_right_" + index } src={ elem.right_img } className="show_img_point" />
                                                    </div>
                                                </>
                                            }
                                        </div>  

                                        <div className="div_input delete_img" onClick={ ()=>{ DeletePointData(elem.id, index, elem.title) } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div> 
                                    </div>
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <select onChange={ (e)=>{ HandleDataPoint('tower', index, e.target.value) } } value={ elem.tower } required>
                                                <option value="">#</option>
                                                <option value="newData">Add new tower</option>
                                                {
                                                    listOptData.map((elem_1, index_1)=>{
                                                        if(elem_1.type == 'tower'){
                                                            return(
                                                                <option value={ elem_1.id } key={ index_1 }>{ elem_1.name }</option>
                                                            )
                                                        }
                                                    })
                                                }
                                            </select>
                                            <span className="name_input">Tower</span>
                                        </div>

                                        {
                                            elem.tower == "newData" ? 
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataPoint('new_tower', index, e.target.value) } } value={ elem.new_tower } maxLength="140" required />
                                                <span className="name_input">New tower*</span>
                                            </div>
                                            : null
                                        }

                                        <div className="div_input space_div">
                                            <select onChange={ (e)=>{ HandleDataPoint('side', index, e.target.value) } } value={ elem.side } required>
                                                <option value="">#</option>
                                                <option value="newData">Add new side</option>
                                                {
                                                    listOptData.map((elem_1, index_1)=>{
                                                        if(elem_1.type == 'side'){
                                                            return(
                                                                <option value={ elem_1.id } key={ index_1 }>{ elem_1.name }</option>
                                                            )
                                                        }
                                                    })
                                                }
                                            </select>
                                            <span className="name_input">Side</span>
                                        </div>

                                        {
                                            elem.side == "newData" ? 
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataPoint('new_side', index, e.target.value) } } value={ elem.new_side } maxLength="140" required />
                                                <span className="name_input">New side*</span>
                                            </div>
                                            : null
                                        }
                                        
                                        <span className="div_input div_add_img">
                                            <div className={ elem.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { elem.statusImg == true ? "Foto adicionada" : "Adicionar foto" }
                                                    <input type="file" onChange={ (e)=>{ HandleDataPoint('img', index, e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                elem.img !='' ?
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleDataPoint('img', index, ''); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    {
                                                        elem.statusImg == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.img)) } }>
                                                            <img alt={ "img_" + index } src={ URL.createObjectURL(elem.img) } className="show_img_point" />
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', elem.img) } }>
                                                            <img alt={ "img_" + index } src={ elem.img } className="show_img_point" />
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </span>

                                        <span className="div_input div_add_img">
                                            <div className={ elem.statusMask == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { elem.statusMask == true ? "Máscara adicionada" : "Adicionar máscara" }
                                                    <input type="file" onChange={ (e)=>{ HandleDataPoint('mask', index, e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                elem.mask !='' ?
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleDataPoint('mask', index, ''); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    {
                                                        elem.statusMask == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.mask)) } }>
                                                            <img alt={ "img_" + index } src={ URL.createObjectURL(elem.mask) } className="show_img_point" />
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', elem.mask) } }>
                                                            <img alt={ "img_" + index } src={ elem.mask } className="show_img_point" />
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </span> 
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="no_data">Nenhum ponto foi adicionado...</div>
                    }
                </div>
                
                <div className="show_page_data">
                    <div className="list_input_data show_list_file">
                        <div className="space_div div_add_img">
                            {
                                listImg.length == 0 ? null :
                                <div className="delete_all" onClick={ ()=>{ DeleteData('delete_all', 0, "Todas as imagens") } }>
                                    Deletar todas as imagens
                                </div>
                            }

                            <div className={ statusFileZip == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusFileZip == true ? ".zip adicionado" : "Adicionar .zip" }
                                    <input type="file" onChange={ (e)=>{ AddFileCompr('zip', e.target.files[0]); } } accept=".zip" />
                                </label>
                            </div>
                            {
                                fileZip == '' ? null :
                                <div className="delete_img_add" onClick={ ()=>{ AddFileCompr('zip', ''); } }>
                                    <SvgDelete className="icons" color="#ffffff" />
                                </div>
                            }

                            <div className={ statusFileCompr == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusFileCompr == true ? "Imagens adicionadas" : "Adicionar imagem" }
                                    <input type="file" onChange={ (e)=>{ AddFileCompr('img', e.target.files); } } accept="image/*" multiple />
                                </label>
                            </div>
                            {
                                fileCompr == '' ? null :
                                <div className="delete_img_add" onClick={ ()=>{ AddFileCompr('img', ''); } }>
                                    <SvgDelete className="icons" color="#ffffff" />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="list_input_data">
                        {
                            listImg.map((elem, index)=>{
                                let nameImg = elem.img.split('/');
                                return(
                                    <div className="div_list_file" key={ index }>
                                        <div className="numb_img">{ index + 1 }</div>
                                        <div className="name_img">
                                            { nameImg[4] }
                                            {
                                                elem.point_selected ?
                                                <>
                                                    <div data-tooltip-id={ "type_data_" + elem.id } data-tooltip-content={ elem.point_inf } data-tooltip-place="top">
                                                        <SvgStar className="icons" color="#0b711c" />
                                                    </div>
                                                    <Tooltip id={ "type_data_" + elem.id } />
                                                </>
                                                : null
                                            }
                                            {/* {
                                                elem.img_point == 0 ? null :
                                                
                                                elem.img_point == 1 ? 
                                                <div data-tooltip-id={ "type_data_" + elem.id } data-tooltip-content="Pontos e dados adicionados!" data-tooltip-place="top">
                                                    <SvgStar className="icons" color="#0b711c" />
                                                </div> : 
                                                <div data-tooltip-id={ "type_data_" + elem.id } data-tooltip-content="Pontos adicionados sem conteúdo" data-tooltip-place="top">
                                                    <SvgStar className="icons" color="#f00000" />
                                                </div>
                                            }
                                            <Tooltip id={ "type_data_" + elem.id } /> */}
                                        </div>
                                        <div className="delete_img">
                                            <div className="show_img_" onClick={ ()=>{ OpenGallery(index, elem.img) } }>
                                                <img alt={ index } src={ elem.thumbnail } className="icons show_img_point" />
                                                {/* SvgImg color="#324d6b" className="icons"/> */}
                                            </div>
                                        </div>                                                 
                                        <div className="delete_img" onClick={ ()=>{ DeleteData('delete_img', elem.id, nameImg[4]) } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>  
                                    </div>
                                )
                            })
                        }                             
                    </div>
                </div>
            </form>
            <PopUP_GeneralTourPoint />
        </div>
    )
}