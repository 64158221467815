import React, { useState, useEffect } from "react";

import { SvgClose, SvgImg, SvgSetaRight } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import Iframe from 'react-iframe';

import './ShowFile.css';

import { Reg_SelectedImgBuilding } from "services/RegisterData";

export default function PopUP_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    
    const [ folder, setFolder ]     = useState('');
    const [ typeFile, setTypeFile ] = useState('');
    const [ indexImg, setIndexImg ] = useState(0);
    const [ listFile, setListFile ] = useState([]);

    const [ mask, setMask ]     = useState('');
    const [ status, setStatus ] = useState(false);
    
    const [ statusBtn, setStatusBtn ] = useState(false);

    function HandleData(index, value){
        const newData = [...listFile];
        newData[index]['imgFixed'] = value;
        setListFile(newData);
    }

    function AddFile(value){
        const newData = [...listFile];
        if(value){
            newData[indexImg]['mask']     = value;
            newData[indexImg]['status_'] = true;
        }else {
            newData[indexImg]['mask']     = '';
            newData[indexImg]['status_'] = false;
        }
        setListFile(newData);
    }

    function SaveImgFixed(id){
        if(listFile[indexImg].imgFixed == true){
            // if(listFile[indexImg].mask == ''){
            //     SetModalData('ReturnResponse', { "page": "missingData", "text": "Ao definir a imagem como fixa, é necessário adicionar uma máscara" });
            //     SetModalState('ReturnResponse', true);
            // }else {                
            //     Reg_SelectedImgBuilding(id, listFile[indexImg].imgFixed, listFile[indexImg].mask, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
            // }
                Reg_SelectedImgBuilding(id, listFile[indexImg].imgFixed, listFile[indexImg].mask, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }else {
            Reg_SelectedImgBuilding(id, listFile[indexImg].imgFixed, listFile[indexImg].mask, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function CallbackSuccess(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setStatus(false);
        SetModalState('ShowFile', false);
    }

    function ShowMask(type, file){
        SetModalData('ShowFileTwo', { "type": type, "file": file });
        SetModalState('ShowFileTwo', true);
    }

    function OpenFile(){
        switch (modalData.type) {
            case 'showIcon':
                return <img alt="img" src={ modalData.file } className="show_img" style={ { width: modalData.width + "px", height: modalData.height + "px" } } />;

            case 'showImg':
                return <img alt="img" src={ modalData.file } className="show_img" />;

            case 'showImg_local':
                return <img alt="img" src={ modalData.file } className="show_img" />;

            case 'showPdf':
                return <Iframe url={ modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative" />

            case 'showPdf_local':
                return <Iframe url={ modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative" />

            case 'vimeo':
                let linkVimeo = modalData.file.split('/');
                return <Iframe className="div_video" src={ "https://player.vimeo.com/video/" + linkVimeo[4] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'youtube':
                let linkYoutube = modalData.file.split('=');
                return <Iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'video_local':
                return (
                    <video width="98%" height="400px" controls>
                        <source src={ modalData.file } type="video/mp4" />
                    </video>
                );
        }
    }

    let count = indexImg;
    function Start(type){
        if(type = 'close'){  
            clearInterval()
        }  
        if(type = 'start'){  
            const startImg = setInterval(() => {
                setIndexImg(count)        
                if(count > listFile.length){
                    clearInterval(startImg);
                }
                count++;
            }, 100);
        }      
    }

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setTypeFile(modalData.type);
            setListFile(modalData.listFile);
            setFolder(modalData.folder);
            setIndexImg(modalData.index);

            setMask(modalData.mask);
            setStatusBtn(modalData.imgFixed);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all show_file" style={ { backgroundColor: '#2d3c53' } }>
                        {
                            typeFile == "list" || typeFile == "list_select" ? 
                            <div className="div_arrow">
                                {
                                    indexImg - 1 >= 0 ?
                                    <div className="arrow" onClick={ ()=>{ setIndexImg(indexImg - 1); } }>
                                        <SvgSetaRight className="icons icons_left" color="#ffffff" />
                                    </div> : 
                                    <div className="arrow">
                                        <SvgSetaRight className="icons icons_left" color="#535353" />
                                    </div>
                                }
                                <div className="space_white" />
                                {
                                    indexImg < listFile.length - 1  ?
                                    <div className="arrow" onClick={ ()=>{ setIndexImg(indexImg + 1) } }>
                                        <SvgSetaRight className="icons" color="#ffffff" />
                                    </div> : 
                                    <div className="arrow">
                                        <SvgSetaRight className="icons" color="#535353" />
                                    </div>
                                }
                            </div>
                            : null
                        }

                        <div className="div_data type_div">
                            <div className="title"></div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {/* {
                                    typeFile == "list" ?
                                    <div className="btn_start" onClick={ ()=>{ Start() } }>Start</div>
                                    : null
                                } */}
                                <div className="add_fixed">
                                    {
                                        typeFile == "list_select" ?
                                        <>
                                            <div className={ listFile[indexImg].status_ == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { listFile[indexImg].status_ == true ? "Máscara adicionada" : "Adicionar máscara" }
                                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" multiple />
                                                </label>
                                            </div>
                                            {
                                                listFile[indexImg].mask == '' ? null : 
                                                listFile[indexImg].status_ == true ?
                                                <div className="show_img_" onClick={ ()=>{ ShowMask('showImg_local', URL.createObjectURL(listFile[indexImg].mask)) } }>
                                                    <SvgImg color="#ffffff" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ ShowMask('showImg', listFile[indexImg].mask) } }>
                                                    <SvgImg color="#ffffff" className="icons"/>
                                                </div>
                                            }
                                            <div className={ listFile[indexImg].imgFixed ? "btn img_selected" : "btn" } onClick={ ()=>{ HandleData(indexImg, !listFile[indexImg].imgFixed) } }>
                                                {
                                                    listFile[indexImg].imgFixed ? "Imagem definida como fixa" : "Definir imagem como fixa"
                                                }
                                            </div>
                                            <div className="btn" onClick={ ()=>{ SaveImgFixed(listFile[indexImg].id) } }>
                                                Salvar
                                            </div>
                                        </> : null
                                    }
                                </div>
                                {
                                    typeFile == "list" || typeFile == "list_select" ? 
                                    <img alt="img" src={ listFile[indexImg].img } className="show_img" /> : 
                                    OpenFile()
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
